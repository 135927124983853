import React from 'react';
import { withApplicationInsights } from '../components/telemetry';
import { Stack, Text, IStackTokens } from '@fluentui/react';

// Stack tokens for spacing
const stackTokens: IStackTokens = { childrenGap: 40 };

const LandingPage = () => {
    return (
        <Stack horizontalAlign="center" verticalAlign="center" verticalFill tokens={stackTokens} styles={{ root: { width: '960px', margin: '0 auto', textAlign: 'center', height: '100vh' } }}>
            <Text variant="large" nowrap block>
                Start a new Chat or continue an existing one
            </Text>
        </Stack>
    );
};

export default withApplicationInsights(LandingPage, 'LandingPage');
