import React, { useState } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

interface GrowthData {
  "Dimension Type": string;
  "Dimension Value": string;
  growth: string;
  growth_percentage: number;
  growth_contribution_percentage: string;
}

interface GrowthChartProps {
  data: GrowthData[];
}

const formatGrowth = (value: string) => {
  const num = parseInt(value, 10);
  return `$${Math.abs(num / 1e6).toFixed(2)}M`;
};

const formatPercentage = (value: number) => `${value.toFixed(2)}%`;

const GrowthChart: React.FC<GrowthChartProps> = ({ data }) => {
  const [activeDimension, setActiveDimension] = useState<string>("Channel");

  const filteredData = data.filter(item => item["Dimension Type"] === activeDimension);

  // Updated color map to use the consistent color scheme
  const colorMap: { [key: string]: string } = {
    Channel: "#54A7A8",  // Main color for Channel
    Region: "#3F7D7E",   // Secondary color for Region
    Segment: "#2A5354"   // Tertiary color for Segment
  };

  const textColor = "#2A5354";  // Consistent text color for all bars

  return (
    <div>
      <div style={{ marginBottom: '20px' }}>
        <button onClick={() => setActiveDimension("Channel")} style={{ marginRight: '10px' }}>Channel</button>
        <button onClick={() => setActiveDimension("Region")} style={{ marginRight: '10px' }}>Region</button>
        <button onClick={() => setActiveDimension("Segment")}>Segment</button>
      </div>
      <ResponsiveContainer width="100%" height={400}>
        <BarChart
          data={filteredData}
          margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="Dimension Value" />
          <YAxis yAxisId="left" orientation="left" stroke={colorMap[activeDimension]} />
          <YAxis yAxisId="right" orientation="right" stroke={textColor} />
          <Tooltip
            contentStyle={{
              backgroundColor: "rgba(255,255,255,0.8)",
              borderColor: "#ccc",
            }}
          />
          <Legend />
          
          <Bar yAxisId="left" dataKey="growth" fill={colorMap[activeDimension]} name="Growth">
            {filteredData.map((entry, index) => (
              <text
                key={index}
                x={index * 200 + 100}
                y={entry.growth.startsWith('-') ? 220 : 180}
                fill={textColor}
                textAnchor="middle"
                dy={-10}
              >
                {formatGrowth(entry.growth)}
              </text>
            ))}
          </Bar>

          <Bar yAxisId="right" dataKey="growth_percentage" fill={textColor} name="Growth Percentage">
            {filteredData.map((entry, index) => (
              <text
                key={index}
                x={index * 200 + 100}
                y={entry.growth_percentage < 0 ? 220 : 180}
                fill={textColor}
                textAnchor="middle"
                dy={-10}
              >
                {formatPercentage(entry.growth_percentage)}
              </text>
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default GrowthChart;
