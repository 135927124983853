import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Line,
  ComposedChart,
} from 'recharts';

interface ProductMetrics {
  year: number;
  unique_products_sold: string;
  total_volume: string;
  avg_volume_per_product: string;
  total_baseline_volume: number;
  total_incremental_volume: number;
  avg_weeks_on_promotion: string;
  product_status: string;
  count: string;
}

interface ProductMetricsChartProps {
  data: ProductMetrics[];
}

const formatNumber = (num: number) => {
  return num.toLocaleString(undefined, { maximumFractionDigits: 0 });
};

const formatDecimal = (num: number) => {
  return num.toLocaleString(undefined, { maximumFractionDigits: 2 });
};

const ProductMetricsChart: React.FC<ProductMetricsChartProps> = ({ data }) => {
  const processedData = data.reduce((acc, curr) => {
    const existingYear = acc.find(item => item.year === curr.year);
    if (existingYear) {
      existingYear[curr.product_status] = parseInt(curr.count);
    } else {
      acc.push({
        year: curr.year,
        unique_products_sold: parseInt(curr.unique_products_sold),
        total_volume: parseInt(curr.total_volume),
        avg_volume_per_product: parseFloat(curr.avg_volume_per_product),
        total_baseline_volume: curr.total_baseline_volume,
        total_incremental_volume: curr.total_incremental_volume,
        avg_weeks_on_promotion: parseFloat(curr.avg_weeks_on_promotion),
        [curr.product_status]: parseInt(curr.count),
      });
    }
    return acc;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }, [] as any[]);

  // Define consistent colors
  const barColor1 = "#54A7A8";   // Primary color
  const barColor2 = "#3F7D7E";   // Secondary color
  const barColor3 = "#2A5354";   // Tertiary color
  const lineColor1 = "#E7E6E6";  // Color for Avg Volume per Product
  const lineColor2 = "#FFC658";  // Color for Avg Weeks on Promotion

  return (
    <ResponsiveContainer width="100%" height={600}>
      <ComposedChart
        data={processedData}
        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="year" />
        <YAxis yAxisId="left" orientation="left" stroke={barColor1} />
        <YAxis yAxisId="right" orientation="right" stroke={lineColor1} />
        <Tooltip formatter={(value, name) => {
          if (name === "Avg Volume per Product" || name === "Avg Weeks on Promotion") {
            return formatDecimal(Number(value));
          }
          return formatNumber(Number(value));
        }} />
        <Legend />

        <Bar yAxisId="left" dataKey="unique_products_sold" fill={barColor1} name="Unique Products Sold" />
        <Bar yAxisId="left" dataKey="total_volume" fill={barColor2} name="Total Volume" />
        <Bar yAxisId="left" dataKey="total_baseline_volume" fill={barColor3} name="Total Baseline Volume" />
        <Bar yAxisId="left" dataKey="total_incremental_volume" fill={lineColor1} name="Total Incremental Volume" />

        <Line yAxisId="right" type="monotone" dataKey="avg_volume_per_product" stroke={lineColor1} name="Avg Volume per Product" />
        <Line yAxisId="right" type="monotone" dataKey="avg_weeks_on_promotion" stroke={lineColor2} name="Avg Weeks on Promotion" />

        <Bar yAxisId="left" dataKey="New Products" stackId="a" fill={barColor1} name="New Products" />
        <Bar yAxisId="left" dataKey="Discontinued Products" stackId="a" fill={barColor2} name="Discontinued Products" />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default ProductMetricsChart;