import React, { useState, CSSProperties } from "react";

export interface DataRow {
  [key: string]: string | number | boolean;
}

type PaginatedTableProps = {
  data: DataRow[] | [];
};

const PaginatedTable: React.FC<PaginatedTableProps> = ({ data }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const totalPages = Math.ceil(data.length / rowsPerPage);
  const displayedData = data.slice(
    currentPage * rowsPerPage,
    (currentPage + 1) * rowsPerPage
  );

  const handlePrevious = () => {
    setCurrentPage((prev) => Math.max(0, prev - 1));
  };

  const handleNext = () => {
    setCurrentPage((prev) => Math.min(totalPages - 1, prev + 1));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setRowsPerPage(Number(event.target.value));
    setCurrentPage(0);
  };

  const cellFormatter = (value: string | number | boolean, key: string) => {
    if( key === "weekendingdate") {
      return value.toString().substring(0, 10);
    }
    if (
      key === "recent_year" ||
      key === "previous_year" ||
      key === "id" ||
      key.toLowerCase() === "year" ||
      key === "month"
    ) {
      return value;
    }
    if (
      key === "growth_contribution_percentage" ||
      key === "revenue_share_percentage" ||
      key === "revenue_growth_percentage" || 
      key === "average_depth_of_discount"
    ) {
      return `${parseFloat(value as string).toFixed(2)}%`;
    }
    if (key === "promo_intensity") {
      return (parseFloat(value as string) * 100).toFixed(2) + "%";
    }
    if (key === "avg_depth_of_discount") {
      return (parseFloat(value as string)).toFixed(2) + "%";
    }

    if (key === "growth" ||
      key === "total_stores_start" ||
      key === "total_volume" ||
      key === "promo_volume" || 
      key === "total_invoice_value" ||
      key === "Total Quantity" ||
      key === "Total Invoice Value" ||
      key === "totalactualinvoicevalue" ||
      key === "totalsimulatedinvoicevalue" ||
      key === "savingspotential"
    ) {
      // make it a whole number and format it to have commas
      return `${parseFloat(value as string).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
    }
    if (key === "volume_uplift_percentage" ||
      key === "cagr"
    ) {
      return `${parseFloat(value as string).toFixed(2)}%`;
    }

    if (key === "avg_volume_per_product" || key === "avg_weeks_on_promotion") {
      return `${parseFloat(value as string).toFixed(2)}`;
    }
    if (
      key === "volume_driven_revenue_growth" ||
      key === "revenue_growth" ||
      key === "total_revenue_all_channels" ||
      key === "total_revenue" ||
      key === "previous_year_revenue" ||
      key === "revenue_per_store"
    ) {
      // make it a whole number and format it to have commas
      return `$${parseFloat(value as string)
        .toFixed(0)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
    }
    if (typeof value === "number") {
      if (
        key === "price_driven_growth" ||
        key === "volume_driven_growth" ||
        key === "mix_driven_growth" ||
        key === "recent_revenue" ||
        key === "previous_revenue" ||
        key === "total_revenue_2021" ||
        key === "total_revenue_2022" ||
        key === "volume_driven_revenue_growth" ||
        key === "revenue_growth" ||
        key === "total_revenue_all_channels" ||
        key === "total_revenue" ||
        key === "total_growth"
      ) {
        // make it a whole number and format it to have commas
        return `$${value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
      }
      if (
        key === "total_baseline_volume" ||
        key === "total_incremental_volume" ||
        key === "incremental_volume" ||
        key === "price_driven_growth" ||
        key === "baseline_volume" ||
        key === "baseline_revenue" ||
        key === "incremental_revenue" ||
        key === "total_optimal_volume" ||
        key === "total_optimal_incremental_volume" ||
        key === "total_previous_volume" ||
        key === "total_previous_incremental_volume" ||
        key === "volume_difference" ||
        key === "incremental_volume_difference" ||
        key === "incremental_volume_difference_percentage" ||
        key === "price_driven_growth" ||
        key === "avg_promo_weeks_per_sku" ||
        key === "avg_incremental_volume_per_promo_week" ||
        key === "avg_baseline_volume_per_sku"
      ) {
        // make it a whole number and format it to have commas
        return `${value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
      }
      if (
        key === "revenue_growth_percentage" ||
        key === "percentage_growth" ||
        key === "growth_contribution_percentage" ||
        key === "revenue_share_percentage" ||
        key === "avg_depth_of_discount" ||
        key === "promo_pressure" ||
        key === "uplift_percentage" ||
        key === "growth_percentage" || 
        key === "optimal_uplift_percentage" ||
        key === "previous_uplift_percentage"
      ) {
        return `${value.toFixed(2)}%`;
      }
      if (
        key === "promo_intensity" ||
        key === "incrementality_ratio"
      ) {
        return (value * 100).toFixed(2) + "%";
      }
      if (
        key === "avg_price_per_ounce" ||
        key === "avg_promo_price_per_ounce" ||
        key === "avg_non_promo_price_per_ounce" ||
        key === "avg_price" ||
        key === "avg_non_promo_price" ||
        key === "avg_promo_price"
      ) {
        return `$${value.toFixed(4)}`;
      }
      return value.toFixed(2);
    }
    return value;
  };

  // Define a common style for both th and td elements
  const commonCellStyle: CSSProperties = {
    textAlign: "left",
    border: "1px solid #2A5354",
    padding: "5px",
  };

  // Adjust the first column width and align numbers to the right
  const getCellStyle = (value: number | string | boolean): CSSProperties => ({
    ...commonCellStyle,
    textAlign: typeof value === "number" ? "right" : "left",
  });

  return (
    <div style={{ padding: "9px" }}>
      <table>
        <thead>
          <tr>
            {data.length > 0 &&
              Object.keys(data[0]).map((key, index) => (
                <th
                  key={key}
                  style={{ ...commonCellStyle, ...{ textAlign: "center" } }}
                >
                  {key}
                </th>
              ))}
          </tr>
        </thead>
        <tbody>
          {displayedData.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {/* {Object.values(row).map((value, colIndex) => (
                <td key={colIndex} style={getCellStyle(value)}>
                  {cellFormatter(value)}
                </td>
              ))} */}
              {Object.entries(row).map(([key, value], colIndex) => (
                <td key={colIndex} style={getCellStyle(value)}>
                  {cellFormatter(value, key)}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>

      {totalPages > 1 && (
        <div>
          <button onClick={handlePrevious} disabled={currentPage === 0}>
            Previous
          </button>
          <span>
            Page {currentPage + 1} of {totalPages}
          </span>
          <button
            onClick={handleNext}
            disabled={currentPage === totalPages - 1}
          >
            Next
          </button>
          <label>
            Rows per page:
            <select value={rowsPerPage} onChange={handleChangeRowsPerPage}>
              {[5, 10, 15, 20].map((value) => (
                <option key={value} value={value}>
                  {value}
                </option>
              ))}
            </select>
          </label>
        </div>
      )}
    </div>
  );
};

export default PaginatedTable;
