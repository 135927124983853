import {
  getTheme,
  IStackStyles,
  mergeStyles,
  Persona,
  PersonaSize,
  Stack,
} from "@fluentui/react";
import React, { FC, ReactElement } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import logo from "../assets/logo.png";

const theme = getTheme();

const logoStyles: IStackStyles = {
  root: {
    width: "300px",
    background: "#2A5354",
    alignItems: "center",
    padding: "0 24px",
  },
};

const logoIconClass = mergeStyles({
  height: 37,
  paddingRight: 10,
  width: 'auto',
  paddingLeft: 10,
});

const toolStackClass: IStackStyles = {
  root: {
    alignItems: "center",
    height: 48,
    paddingRight: 10,
  },
};

const logoutButtonClass = mergeStyles({
  marginRight: "10px",
  borderRadius: "20px",
  padding: "5px 20px",
  cursor: "pointer",
  border: `1px solid ${theme.palette.neutralPrimary}`,
  backgroundColor: theme.palette.neutralLighter,
  ':hover': {
    backgroundColor: theme.palette.neutralLight,
  },
});

const Header: FC<{ username: string }> = (props): ReactElement => {
  const { username } = props;
  const { logout } = useAuth0();
  return (
    <Stack horizontal>
      <Stack horizontal styles={logoStyles}>
        <img src={logo} alt="Logo" className={logoIconClass} />
      </Stack>
      <Stack.Item grow={1}>
        <div></div>
      </Stack.Item>
      <Stack.Item>
        <Stack horizontal styles={toolStackClass} grow={1}>
          <button
            className={logoutButtonClass}
            onClick={() =>
              logout({ logoutParams: { returnTo: window.location.origin } })
            }
          >
            Log Out
          </button>
          <Persona size={PersonaSize.size24} text={username} />
        </Stack>
      </Stack.Item>
    </Stack>
  );
};

export default Header;
