import React from 'react';
import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  ResponsiveContainer
} from 'recharts';

interface DataItem {
    product_id: string;
    avg_depth_of_discount: number;
  }
  
  interface DiscountDepthChartProps {
    data: DataItem[];
  }
  
  const DiscountDepthChart: React.FC<DiscountDepthChartProps> = ({ data }) => {
  
  const formatGrowth = (tickItem: number) => {
    return `${(tickItem * 100).toFixed(2)}%`;
  };
    
  return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="product_id" tick={{ fill: 'white' }} />
        <YAxis tick={{ fill: 'white' }} tickFormatter={formatGrowth} />
        <Tooltip 
          contentStyle={{ backgroundColor: 'rgba(0,0,0,0.9)', borderColor: '#000', color: '#fff' }}
          cursor={false}
        />
        <Bar dataKey="avg_depth_of_discount" fill="#2A5354" name="Avg Depth of Discount" />
      </BarChart>
    </ResponsiveContainer>
  );
}

export default DiscountDepthChart;
