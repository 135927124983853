import React, { useEffect, useState } from "react";
import SQLTableLoader from "./sqlTableLoader";
import { ChatInputData, commonIconImageStyle } from "../pages/chatPage";
import { getTheme, mergeStyles } from "@fluentui/react";
import { TbSql } from "react-icons/tb";
import axios from "axios";
import config from "../config";
import HCChart from "./hcChart";

const theme = getTheme();

const logoutButtonClass = mergeStyles({
  marginRight: "10px",
  borderRadius: "20px",
  padding: "5px 20px",
  cursor: "pointer",
  border: `1px solid ${theme.palette.neutralPrimary}`,
  backgroundColor: theme.palette.neutralLighter,
  ":hover": {
    backgroundColor: theme.palette.neutralLight,
  },
});

const ChatItemDetails = ({ chatItem }: { chatItem: ChatInputData }) => {
  const [activeTab, setActiveTab] = useState("Response");
  const [sqlFormatted, setSqlFormatted] = useState(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [tableData, setTableData] = useState<any[]>([]);
  const [fetchError, setFetchError] = useState<string | null>(null);

  type ChatItem = {
    message: string;
    response: {
      sql: string | { sql_cmd?: string };
    };
  };

  const getSqlCmdFromChatItem = (chatItem: ChatItem): string => {
    // Check if chatItem.response.sql exists
    if (chatItem && chatItem.response && chatItem.response.sql) {
      try {
        let sqlData = chatItem.response.sql;

        // If chatItem.response.sql is a string, attempt to parse it
        if (typeof sqlData === "string") {
          sqlData = JSON.parse(sqlData);
        }

        // If sqlData is an object and has a sql_cmd property, return it
        if (
          typeof sqlData === "object" &&
          "sql_cmd" in sqlData &&
          sqlData.sql_cmd
        ) {
          return sqlData.sql_cmd;
        }
      } catch (error) {
        console.error("Error handling chatItem.response.sql:", error);
      }
    }

    // Return an empty string if sql_cmd is not found or any error occurs
    return "";
  };

  const canThisBeAChart = (chatItem: ChatItem): number => {
    const demo_script = {
      "What was the year over year revenue growth in the Beverages category between 2021 and 2022?": 277,
      "For the brand Sunny Mint Soda calculate % growth 2021 to 2022 by channel.": 457,
      "For the brand Sunny Mint Soda what was the volume driven revenue growth in DRUG 2021 to 2022?": 469,
      "What was the promo strategy of the brand Sunny Mint Soda in DRUG, based on 2022 data?": 480,
      "What was the promo calendar for the brand Sunny Mint Soda, by products in 2022, weeks on promo.": 216,
      "Based on the data, which products of the brand Sunny Mint Soda have opportunity to drop prices further when promoted?": 147,
      "Analyze opportunities for Sunny Mint Soda to improve its price pack curve.": 185,
      "Calculate price ladders for the brands Sunny Mint Soda, Crisp Citrus Brew and Icy Cherry Juice in 2022.": 403,
      "What was the promotional effectiveness in 2022 and 2023?": 1101,
      "Show me growth statistics across all dimension types available in the data.": 1113,
      "Analyze the share of net revenue of company operates stores between 2022 and 2023.": 975,
      "What were the high level drivers of growth for company-operated stores 2022 to 2023?": 983,
      "Analyze the PVM variance for company-operated stores between 2022 and 2023 for all products.": 988,
      "Business drivers analysis of volumes for company-operated stores in the US from 2022 to 2023.": 1055,
      "Which promo types had the highest volume uplift 2023?": 1105,
      // Argon
      "Who were the top 10 vendors by total invoice value in 2023 full year?": 1196,
      "How many of each terminal type did we purchase in 2023? products, quantity, invoice value": 1198,
      "Show me monthly purchase stats for on-premise terminal products in 2023.": 1211,
      // New
      "Give me the top 30 brands by revenue CAGR 2021 and 2023. Where does Sunny Mint Soda stand?": 1317,
      "Calculate the growth contribution of the brand Sunny Mint Soda to its manufacturer between 2022 and 2023, by channel.": 1309,
      "Analyze the PVM variance of the brand Sunny Mint Soda in the channel DRUG.": 1312,
      "Why did total volume decline for Sunny Mint Soda in DRUG in 2023?": 1363,
      // "Give me the optimal promo calendar for 2024 for the brand Sunny Mint Soda, in DRUG, by product.": 1360,
      // "Calculate the impact of the optimal promo calendar, brand Sunny Mint Soda, channel DRUG.": 1367,
    };
    if (demo_script[chatItem.message as keyof typeof demo_script]) {
      return demo_script[chatItem.message as keyof typeof demo_script];
    } else {
      return 0;
    }
  };

  const shemaToUse = (chatItem: ChatItem): string => {
    const demo_script_sbx = {
      "Analyze the year over year growth and the growth contribution of net revenue from 2022 to 2023 of all dimensions available in the data.": 974,
      "Analyze the share of net revenue of company operates stores between 2022 and 2023.": 975,
      "What were the high level drivers of growth for company-operated stores 2022 to 2023?": 983,
      "Analyze the PVM variance for company-operated stores between 2022 and 2023 for all products.": 988,
      "Business drivers analysis of volumes for company-operated stores in the US from 2022 to 2023.": 1055,
      "What was the promotional effectiveness in 2022 and 2023?": 1101,
      "Which promo types had the highest volume uplift 2023?": 1105,
      "Show me growth statistics across all dimension types available in the data.": 1113,
      "Recommend the optimal promo calendar for 2024.": 1117,
      "What's the impact of the optimal promo calendar in 2024?": 1120,
    };
    const demo_script_nielsen = {
      "What was the year over year revenue growth in the Beverages category between 2021 and 2022?": 277,
      "For the brand Sunny Mint Soda calculate % growth 2021 to 2022 by channel.": 457,
      "For the brand Sunny Mint Soda what was the volume driven revenue growth in DRUG 2021 to 2022?": 469,
      "What was the promo strategy of the brand Sunny Mint Soda in DRUG, based on 2022 data?": 480,
      "What was the promo calendar for the brand Sunny Mint Soda, by products in 2022, weeks on promo.": 216,
      "Based on the data, which products of the brand Sunny Mint Soda have opportunity to drop prices further when promoted?": 147,
      "Analyze opportunities for Sunny Mint Soda to improve its price pack curve.": 185,
      "Calculate price ladders for the brands Sunny Mint Soda, Crisp Citrus Brew and Icy Cherry Juice in 2022.": 403,
      "Count inconsistencies between city and coordinates.": 672,
      "How many conflicts in data do you see?": 669,
      "Show me five examples of city standardization issues.": 668,
      "Give me a count of city standardization issues.": 667,
      "How many records have cities not related to Hungary?": 665,
      "How many records contain misspelled cities?": 695,
      "Give me a list of records with invalid cities.": 647,
      "Give me a count of invalid records: cities, geocoordinates.": 646,
      "How many duplicate entries and null entries are there in the data?": 644,
      "Please provide summary statistics for the dataset.": 643,
    };
    const demo_script_argon = {
      "Who were the top 10 vendors by total invoice value in 2023 full year?": 1196,
      "How many of each terminal type did we purchase in 2023? products, quantity, invoice value": 1198,
      "In 2023 what was the price dispersion per product for on-premise terminals?": 1204,
      "How much savings potential is there by changing suppliers for on-premise terminals?": 1209,
      "Show me monthly purchase stats for on-premise terminal products in 2023.": 1211,
    };
    if (demo_script_sbx[chatItem.message as keyof typeof demo_script_sbx]) {
      return "SBUX";
    } else if (
      demo_script_nielsen[chatItem.message as keyof typeof demo_script_nielsen]
    ) {
      return "pos_nielsen_1";
    } else if (
      demo_script_argon[chatItem.message as keyof typeof demo_script_argon]
    ) {
      return "Argon";
    } else {
      return "";
    }
  };

  const formatSQL = (sql: string): string => {
    // Normalize spaces and remove line breaks to start with a clean slate
    let formattedSQL = sql.replace(/\s+/g, " ").trim();

    // Define patterns to insert line breaks after these keywords or symbols
    const breakAfter = [
      "WITH",
      "SELECT",
      "FROM",
      "WHERE",
      "GROUP BY",
      "ORDER BY",
      "JOIN",
      ",",
      "AND",
      "OR",
      "),",
    ];

    // Insert line breaks after specified keywords
    breakAfter.forEach((keyword) => {
      if (keyword === "),") {
        formattedSQL = formattedSQL.replace(/,\s*/g, ",\n");
      } else {
        const pattern = new RegExp(`\\b${keyword}\\b`, "gi");
        formattedSQL = formattedSQL.replace(pattern, (match) => `\n${match}`);
      }
    });

    // Additional formatting for indentation and line breaks before certain keywords
    const breakBefore = ["FROM", "WHERE", "GROUP BY", "ORDER BY", "JOIN"];
    breakBefore.forEach((keyword) => {
      const pattern = new RegExp(`\\b${keyword}\\b`, "gi");
      formattedSQL = formattedSQL.replace(pattern, (match) => `\n${match}`);
    });

    // Handle indentation for nested queries or blocks
    let indentLevel = 0;
    let formattedSQLLines = formattedSQL.split("\n");
    formattedSQLLines = formattedSQLLines.map((line) => {
      if (line.includes("(") && !line.includes(")")) {
        indentLevel++;
      } else if (!line.includes("(") && line.includes(")")) {
        indentLevel = Math.max(indentLevel - 1, 0);
      }
      const indentedLine = "  ".repeat(indentLevel) + line.trim();
      if (line.includes(")") && !line.includes("(")) {
        indentLevel = Math.max(indentLevel - 1, 0);
      }
      return indentedLine;
    });

    // Reassemble the formatted SQL
    return formattedSQLLines.join("\n").trim();
  };
  const parsedSql = getSqlCmdFromChatItem(chatItem);
  const hasSQL = parsedSql.length > 0 ? true : false;
  const hasChart = canThisBeAChart(chatItem);
  const schema = shemaToUse(chatItem);

  // const hasDefinitions = chatItem.response.definitions;

  const toggleTab = (tabName: string) => {
    if (activeTab === tabName) {
      setActiveTab(""); // Resetting the activeTab if the same tab is clicked again
    } else {
      setActiveTab(tabName);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setFetchError(null);
      try {
        const response = await axios.post(
          `${config.api.baseUrl}/${
            chatItem.response.sql_id === 0
              ? "sql/read_table"
              : "sql/stored_table"
          }`,
          {
            sql: chatItem.response.sql_id === 0 ? parsedSql : undefined,
            sql_id:
              chatItem.response.sql_id !== 0
                ? chatItem.response.sql_id
                : undefined,
            schema,
          }
        );
        const data =
          chatItem.response.sql_id === 0
            ? response.data.rows
            : JSON.parse(response.data.stored_table).rows;
        setTableData(data);
      } catch (error) {
        setFetchError("Failed to load table data.");
        console.error("Error fetching table data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (hasSQL) {
      fetchData();
    }
  }, [parsedSql, chatItem.response.sql_id, hasSQL, schema]);

  return (
    <div>
      <div style={{ paddingBottom: "15px" }}>
        {hasSQL && (
          <>
            <button
              className={logoutButtonClass}
              onClick={() => toggleTab("SQL")}
            >
              SQL
            </button>
            <button
              className={logoutButtonClass}
              onClick={() => toggleTab("Table")}
            >
              Table
            </button>
            {hasChart > 0 && (
              <>
                <button
                  className={logoutButtonClass}
                  onClick={() => toggleTab("Chart")}
                >
                  Chart
                </button>
              </>
            )}
          </>
        )}
        {/* {hasDefinitions && (
          <button onClick={() => toggleTab("Definition")}>Definition</button>
        )} */}
      </div>

      {activeTab === "SQL" && hasSQL && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            paddingTop: "5px",
            paddingBottom: "5px",
            gap: "10px",
          }}
        >
          <div
            onClick={() => setSqlFormatted(!sqlFormatted)}
            className={commonIconImageStyle}
          >
            <TbSql size={24} />
          </div>{" "}
          {sqlFormatted ? (
            parsedSql
          ) : (
            <pre>
              <code>{formatSQL(parsedSql)}</code>
            </pre>
          )}
        </div>
      )}

      {activeTab === "Table" && hasSQL && (
        <SQLTableLoader
          isLoading={isLoading}
          error={fetchError}
          tableData={tableData}
        />
      )}

      {/* {activeTab === "Definition" && hasDefinitions && (
        <p>
          <strong>Definition:</strong>{" "}
          {chatItem.response.definitions.join(", ")}
        </p>
      )} */}
      {activeTab === "Chart" && hasSQL && hasChart > 0 && (
        <HCChart
          isLoading={isLoading}
          error={fetchError}
          tableData={tableData}
          chartID={hasChart}
        />
      )}
    </div>
  );
};

export default ChatItemDetails;
