import { Dispatch } from "react";
import { TodoActions } from "../actions/common";
import { TodoItem } from "./todoItem";
import { TodoList } from "./todoList";
import { Chat } from "./chat";

export interface AppContext {
    state: ApplicationState
    dispatch: Dispatch<TodoActions>
}

export interface ApplicationState {
    lists?: TodoList[]
    selectedList?: TodoList
    selectedItem?: TodoItem
    chats?: Chat[]
    selectedChat?: Chat
}

export const getDefaultState = (): ApplicationState => {
    return {
        lists: undefined,
        selectedList: undefined,
        selectedItem: undefined,
        chats: undefined,
        selectedChat: undefined,
    }
}

