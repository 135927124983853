import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';

interface ProductData {
  product_name: string;
  avg_non_promo_price: number;
  avg_promo_price: number;
  avg_price_elasticity: number;
  price_index: number;
  incrementality_ratio: number;
}

interface ProductChartProps {
  data: ProductData[];
}

const formatGrowth = (tickItem: number) => {
  return `${(tickItem * 100).toFixed(0)}%`;
};
const ProductPriceChart: React.FC<ProductChartProps> = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="product_name" tick={{ fill: 'white' }} />
        <YAxis tick={{ fill: 'white' }} tickFormatter={formatGrowth} />
        <Tooltip 
          contentStyle={{ backgroundColor: 'rgba(0,0,0,0.9)', borderColor: '#000', color: '#fff' }}
          cursor={false}
        />
        <Legend />
        <Bar dataKey="avg_non_promo_price" fill="#2A5354" name="Avg Non-Promo Price" />
        <Bar dataKey="avg_promo_price" fill="#3F7D7E" name="Avg Promo Price" />
      </BarChart>
    </ResponsiveContainer>
  );
}

export default ProductPriceChart;
