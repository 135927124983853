import React from 'react';
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  LabelList,
} from 'recharts';

interface RevenueGrowthData {
  volume_driven_revenue_growth: number;
}

interface RevenueGrowthProps {
  data: RevenueGrowthData[];
}

const RevenueGrowth: React.FC<RevenueGrowthProps> = ({ data }) => {
    const formatRevenue = (tickItem: number) => {
        return `$${(tickItem / 1e6).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}M`;
    };
    return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart layout="vertical" data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis type="number" stroke="#fff" />
        <YAxis type="category" dataKey="volume_driven_revenue_growth" stroke="#fff" tickFormatter={formatRevenue} />
        <Tooltip 
          contentStyle={{ backgroundColor: 'rgba(0,0,0,0.9)', borderColor: '#000', color: '#fff' }}
          cursor={false}
        />
        <Bar dataKey="volume_driven_revenue_growth" fill="#2A5354" barSize={20}>
          <LabelList dataKey="volume_driven_revenue_growth" position="insideLeft" formatter={(value: number) => `$${value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`} />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default RevenueGrowth;
