import React from "react";
import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
  ResponsiveContainer,
} from "recharts";

interface PromoData {
  product_id: string;
  avg_depth_of_discount: number;
  promo_intensity: number;
  promo_pressure: number;
}

interface PromoChartProps {
  data: PromoData[];
}

const formatGrowth = (tickItem: number) => {
  return `${(tickItem * 100).toFixed(2)}%`;
};

const PromoChart: React.FC<PromoChartProps> = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart
        data={data}
        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="product_id" tick={{ fill: 'white' }} />
        <YAxis tick={{ fill: 'white' }} tickFormatter={formatGrowth} />
        <Tooltip
          contentStyle={{
            backgroundColor: "rgba(0,0,0,0.9)",
            borderColor: "#000",
            color: "#fff",
          }}
          cursor={false}
        />
        <Legend />
        <Bar
          dataKey="avg_depth_of_discount"
          fill="#2A5354"
          name="Avg Depth of Discount"
        />
        <Bar dataKey="promo_intensity" fill="#3F7D7E" name="Promo Intensity" />
        <Bar dataKey="promo_pressure" fill="#54A7A8" name="Promo Pressure" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default PromoChart;
