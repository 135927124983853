import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';

interface BrandData {
  brand: string;
  avg_price: number;
  avg_promo_price: number;
  avg_non_promo_price: number;
}

interface BrandPriceChartProps {
  data: BrandData[];
}

const BrandPriceChart: React.FC<BrandPriceChartProps> = ({ data }) => {
  const formatGrowth = (tickItem: number) => {
    return `${(tickItem * 100).toFixed(0)}%`;
  };

  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="brand" tick={{ fill: 'white' }} />
        <YAxis tick={{ fill: 'white' }} tickFormatter={formatGrowth} />
        <Tooltip 
          contentStyle={{ backgroundColor: 'rgba(0,0,0,0.9)', borderColor: '#000', color: '#fff' }}
          cursor={false}
        />
        <Legend />
        <Bar dataKey="avg_price" fill="#2A5354" name="Avg Price" />
        <Bar dataKey="avg_promo_price" fill="#3F7D7E" name="Avg Promo Price" />
        <Bar dataKey="avg_non_promo_price" fill="#54A7A8" name="Avg Non-Promo Price" />
      </BarChart>
    </ResponsiveContainer>
  );
}

export default BrandPriceChart;
