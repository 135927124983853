import React from 'react';
import {
  ComposedChart,
  Bar,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

interface PromoMetrics {
  year: number;
  total_volume: string;
  promo_volume: string;
  incremental_volume: number;
  avg_depth_of_discount: number;
  volume_uplift: number;
  promo_intensity: string;
  promo_pressure: number;
}

interface PromoMetricsChartProps {
  data: PromoMetrics[];
}

const formatLargeNumber = (num: number) => {
  return (num / 1000000).toFixed(2) + 'M';
};

const formatPercentage = (num: number) => {
  return (num * 100).toFixed(2) + '%';
};

const PromoMetricsChart: React.FC<PromoMetricsChartProps> = ({ data }) => {
  const processedData = data.map(item => ({
    ...item,
    total_volume: parseInt(item.total_volume),
    promo_volume: parseInt(item.promo_volume),
    promo_intensity: parseFloat(item.promo_intensity),
  }));

  // Define colors
  const barColor1 = '#54A7A8'; // Consistent color for bars
  const barColor2 = '#3F7D7E'; // Alternate bar color
  const lineColor1 = '#2A5354'; // Consistent color for lines

  return (
    <ResponsiveContainer width="100%" height={600}>
      <ComposedChart
        data={processedData}
        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="year" />
        <YAxis yAxisId="left" orientation="left" stroke={barColor1} />
        <YAxis yAxisId="right" orientation="right" stroke={lineColor1} />
        <Tooltip 
          formatter={(value, name) => {
            if (name === 'total_volume' || name === 'promo_volume' || name === 'incremental_volume') {
              return formatLargeNumber(Number(value));
            }
            if (name === 'avg_depth_of_discount' || name === 'volume_uplift' || name === 'promo_intensity') {
              return formatPercentage(Number(value));
            }
            return value;
          }}
        />
        <Legend />

        <Bar yAxisId="left" dataKey="total_volume" fill={barColor1} name="Total Volume">
          {processedData.map((entry, index) => (
            <text
              x={index * 300 + 150}
              y={entry.total_volume > 0 ? 20 : 580}
              fill={barColor1}
              textAnchor="middle"
              dy={entry.total_volume > 0 ? -10 : 10}
            >
              {formatLargeNumber(entry.total_volume)}
            </text>
          ))}
        </Bar>
        <Bar yAxisId="left" dataKey="promo_volume" fill={barColor2} name="Promo Volume">
          {processedData.map((entry, index) => (
            <text
              x={index * 300 + 150}
              y={entry.promo_volume > 0 ? 100 : 500}
              fill={barColor2}
              textAnchor="middle"
              dy={entry.promo_volume > 0 ? -10 : 10}
            >
              {formatLargeNumber(entry.promo_volume)}
            </text>
          ))}
        </Bar>
        <Bar yAxisId="left" dataKey="incremental_volume" fill={barColor1} name="Incremental Volume">
          {processedData.map((entry, index) => (
            <text
              x={index * 300 + 150}
              y={entry.incremental_volume > 0 ? 180 : 420}
              fill={barColor1}
              textAnchor="middle"
              dy={entry.incremental_volume > 0 ? -10 : 10}
            >
              {formatLargeNumber(entry.incremental_volume)}
            </text>
          ))}
        </Bar>

        <Line yAxisId="right" type="monotone" dataKey="avg_depth_of_discount" stroke={lineColor1} name="Avg Depth of Discount" />
        <Line yAxisId="right" type="monotone" dataKey="volume_uplift" stroke={barColor2} name="Volume Uplift" />
        <Line yAxisId="right" type="monotone" dataKey="promo_intensity" stroke={barColor1} name="Promo Intensity" />
        <Line yAxisId="right" type="monotone" dataKey="promo_pressure" stroke={lineColor1} name="Promo Pressure" />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default PromoMetricsChart;
