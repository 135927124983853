import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Cell } from 'recharts';

type TerminalData = {
  "Terminal Type": string;
  "Number of Products": number;
  "Total Quantity": number;
  "Total Invoice Value": number;
};

type TerminalTypeChartProps = {
  data: TerminalData[];
};

const TerminalTypeChart: React.FC<TerminalTypeChartProps> = ({ data }) => {
  const colors = ['#54A7A8', '#3F7D7E'];

  const formatYAxis = (value: number) => {
    if (value >= 1000000) {
      return `$${(value / 1000000).toFixed(1)}M`;
    }
    return `$${(value / 1000).toFixed(0)}K`;
  };

  const formatTooltip = (value: number, name: string) => {
    if (name === "Total Invoice Value") {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(value);
    }
    return value.toLocaleString();
  };

  const processedData = data.map(item => ({
    ...item,
    "Number of Products": Number(item["Number of Products"]),
    "Total Quantity": Number(item["Total Quantity"]),
    "Total Invoice Value": Number(item["Total Invoice Value"])
  }));

  const sortedData = [...processedData].sort((a, b) => 
    b["Total Invoice Value"] - a["Total Invoice Value"]
  );

  return (
    <ResponsiveContainer width="100%" height={600}>
      <BarChart
        data={sortedData}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 160,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis 
          dataKey="Terminal Type" 
          angle={-45} 
          textAnchor="end"
          interval={0}
          height={150}
        />
        <YAxis 
          yAxisId="left" 
          tickFormatter={formatYAxis} 
          label={{ value: 'Total Invoice Value', angle: -90, position: 'insideLeft' }}
        />
        <YAxis 
          yAxisId="right" 
          orientation="right"
          label={{ value: 'Total Quantity', angle: 90, position: 'insideRight' }}
        />
        <Tooltip formatter={formatTooltip} />
        <Legend />
        <Bar dataKey="Total Invoice Value" yAxisId="left" name="Total Invoice Value">
          {sortedData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
          ))}
        </Bar>
        <Bar dataKey="Total Quantity" yAxisId="right" name="Total Quantity" fill="#8884d8" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default TerminalTypeChart;