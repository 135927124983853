import React, { FC, ReactElement } from 'react';
import Header from './header';
import { Routes, Route } from 'react-router-dom';
import { Stack } from '@fluentui/react';
import { headerStackStyles, mainStackStyles, rootStackStyles, sidebarStackStyles } from '../ux/styles';
import { useAuth0 } from "@auth0/auth0-react";
import ChatPage from '../pages/chatPage';
import Menu from './chatSidebar';
import LandingPage from '../pages/landingPage';
import DevChatPage from '../pages/devChatPage';

const Layout: FC = (): ReactElement => {
    const { isAuthenticated, isLoading, loginWithRedirect, user } = useAuth0();

    if (isLoading) {
        return <div>Loading ...</div>;
    }

    const appParts = (<><Stack.Item styles={headerStackStyles}>
        <Header username={user?.name ?? ''}></Header>
    </Stack.Item><Stack horizontal grow={1}>
            <Stack.Item styles={sidebarStackStyles}>
                <Menu username={user?.name ?? ''} />
            </Stack.Item>
            <Stack.Item grow={1} styles={mainStackStyles}>
                <Routes>
                    <Route path="/chat/:chatId" element={<ChatPage username={user?.name ?? ''} />} />
                    <Route path="/chat" element={<ChatPage username={user?.name ?? ''} />} />
                    <Route path="/dev_chat" element={<DevChatPage username={user?.name ?? ''} />} />
                    <Route path="/" element={<LandingPage />} />
                </Routes>
            </Stack.Item>
            <Stack.Item styles={sidebarStackStyles}>
                {/* <TodoItemDetailPane
                    item={appContext.state.selectedItem}
                    onEdit={onItemEdited}
                    onCancel={onItemEditCancel} /> */}
            </Stack.Item>
        </Stack></>);

    return (
        <Stack styles={rootStackStyles}>
            {isAuthenticated && (appParts)}
            {!isAuthenticated && (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <button
                        onClick={() => loginWithRedirect()}
                        style={{ textAlign: 'center' }}
                    >
                        Log In
                    </button>
                </div>
            )}
        </Stack>
    );
}

export default Layout;
