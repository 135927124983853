import axios from "axios";
import React, { FC, ReactElement, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import config from "../config";

const Menu: FC<{ username: string }> = (props): ReactElement => {
  const { username } = props;
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [chatList, setChatList] = useState<
    { id: string; description: string }[]
  >([]);
  const navigate = useNavigate();
  const [devMode, setDevMode] = useState<boolean>(false);


  useEffect(() => {
    const fetchChatList = async () => {
      try {
        const response = await axios.post(
          `${config.api.baseUrl}/chat/chat_list`,
          { username }
        );
        if (response.data) {
          if (response.data.rows.length > 0) {
            setChatList(response.data.rows);
            setDevMode(response.data?.dev_flag === "true");
          }
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error fetching IP address:", error);
        setIsLoading(false);
      }
    };

    fetchChatList();
  }, [username]);

  // Define the base button style
  const buttonStyle = {
    display: "flex",
    width: "251px",
    padding: "14.5px 144px 14.5px 13px",
    alignItems: "center",
    gap: "12px",
    borderRadius: "0px",
    border: "0px solid",
    borderColor: "#CACBCE",
    color: "#FAFAFA",
    backgroundColor: "black",
    backgroundColorMO: "#3F7D7E",
    cursor: "pointer",
  };

  // Define the hover style
  const hoverStyle = {
    ...buttonStyle,
    backgroundColor: "#575757", // Change this color as needed
  };

  // Function to handle chat creation
  const onChatCreate = (id: string) => {
    navigate(`/chat/${id}`);
  };

  const onDevChatCreate = () => {
    navigate(`/dev_chat/`);
  };
  // Render method
  return (
    <>
      {isLoading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 1000,
          }}
        >
          <p style={{ color: "white" }}>Loading...</p>
        </div>
      )}
      <div style={{ padding: "24px" }}>
        <button
          key={991991}
          onMouseEnter={(e) =>
            (e.currentTarget.style.backgroundColor = buttonStyle.backgroundColorMO)
          }
          onMouseLeave={(e) =>
            (e.currentTarget.style.backgroundColor =
              buttonStyle.backgroundColor)
          }
          style={buttonStyle}
          onClick={() => onChatCreate("0")}
        >
          New Chat
        </button>
        <p>Previous 30 Days</p>
        {chatList.map((chat) => (
          <button
            key={chat.id}
            onMouseEnter={(e) =>
              (e.currentTarget.style.backgroundColor =
                buttonStyle.backgroundColorMO)
            }
            onMouseLeave={(e) =>
              (e.currentTarget.style.backgroundColor =
                buttonStyle.backgroundColor)
            }
            style={buttonStyle}
            onClick={() => onChatCreate(chat.id)}
          >
            {chat.description}
          </button>
        ))}
        {/* Conditional rendering based on develop_mode */}
        {devMode && (
          <button
          key={888888}
          onMouseEnter={(e) =>
            (e.currentTarget.style.backgroundColor = buttonStyle.backgroundColorMO)
          }
          onMouseLeave={(e) =>
            (e.currentTarget.style.backgroundColor =
              buttonStyle.backgroundColor)
          }
          style={buttonStyle}
          onClick={() => onDevChatCreate()}
        >
          Dev Chat
        </button>
        )}
      </div>
    </>
  );
};

export default Menu;
