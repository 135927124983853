import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';

type SKUData = {
  unique_skus_sold: string;
  avg_baseline_volume_per_sku: number;
  avg_incremental_volume_per_sku: number;
  avg_promo_weeks_per_sku: string;
  avg_incremental_volume_per_promo_week: number;
};

type SKUPerformanceDashboardProps = {
  data: SKUData[];
};

const formatNumber = (value: number) => 
  new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(value);

const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-black text-white p-2 rounded shadow-lg">
        <p className="font-bold">{label}</p>
        {payload.map((entry: any, index: number) => (
          <p key={index} style={{ color: entry.color }}>
            {`${entry.name}: ${formatNumber(entry.value)}`}
          </p>
        ))}
      </div>
    );
  }
  return null;
};

const SKUPerformanceDashboard: React.FC<SKUPerformanceDashboardProps> = ({ data }) => {
  const skuData = data[0];

  const volumeData = [
    { name: 'Avg Baseline Volume per SKU', value: skuData.avg_baseline_volume_per_sku },
    { name: 'Avg Incremental Volume per SKU', value: skuData.avg_incremental_volume_per_sku },
  ];

  const promoData = [
    { name: 'Promo Weeks', value: parseInt(skuData.avg_promo_weeks_per_sku) },
    { name: 'Non-Promo Weeks', value: 52 - parseInt(skuData.avg_promo_weeks_per_sku) },
  ];

  const COLORS = ["#54A7A8", "#3F7D7E", "#2A5354"];

  return (
    <div className="flex flex-col space-y-8">
      <div className="text-center">
        <h2 className="text-2xl font-bold">SKU Performance Dashboard</h2>
        <p className="text-xl">Unique SKUs Sold: {skuData.unique_skus_sold}</p>
      </div>

      <div className="grid grid-cols-2 gap-4">
        <div>
          <h3 className="text-lg font-semibold mb-2">Volume Comparison</h3>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={volumeData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis tickFormatter={formatNumber} />
              <Tooltip cursor={false} content={<CustomTooltip />} />
              <Legend />
              <Bar dataKey="value" fill="#54A7A8" />
            </BarChart>
          </ResponsiveContainer>
        </div>

        <div>
          <h3 className="text-lg font-semibold mb-2">Promo vs Non-Promo Weeks</h3>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie
                data={promoData}
                cx="50%"
                cy="50%"
                labelLine={false}
                label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                outerRadius={80}
                fill="#8884d8"
                dataKey="value"
              >
                {promoData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
        </div>
      </div>

      <div>
        <h3 className="text-lg font-semibold mb-2">Avg Incremental Volume per Promo Week</h3>
        <p className="text-xl font-bold">{formatNumber(skuData.avg_incremental_volume_per_promo_week)}</p>
      </div>
    </div>
  );
};

export default SKUPerformanceDashboard;