import React from "react";
import PaginatedTable from "./paginatedTable";

interface SQLTableLoaderProps {
  isLoading: boolean;
  error: string | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tableData: any[];
}

const SQLTableLoader: React.FC<SQLTableLoaderProps> = ({ isLoading, error, tableData }) => {
  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
      <PaginatedTable data={tableData} />
      {/* <DateColumnSelector data={tableData} /> */}
    </>
  );
};

export default SQLTableLoader;
