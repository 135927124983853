import React from 'react';
import {
  BarChart,
  Bar,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

interface RevenueData {
  Year: number;
  total_revenue: string;
  total_stores_start: string;
  revenue_per_store: string;
  previous_year_revenue: string | null;
  revenue_growth: string | null;
  revenue_growth_percentage: string | null;
}

interface RevenueGrowthChartProps {
  data: RevenueData[];
}

const formatRevenue = (value: string | null) => {
  if (!value) return 'N/A';
  const num = parseInt(value, 10);
  return `$${(num / 1e9).toFixed(2)}B`;
};

const formatRevenuePerStore = (value: string) => {
  const num = parseFloat(value);
  return `$${num.toFixed(0)}`;
};

const formatPercentage = (value: string | null) => {
  if (!value) return 'N/A';
  return `${parseFloat(value).toFixed(2)}%`;
};

const RevenueGrowthChart: React.FC<RevenueGrowthChartProps> = ({ data }) => {
  // Define colors
  const barColor1 = '#54A7A8';  // Primary bar color for Total Revenue
  const barColor2 = '#3F7D7E';  // Secondary bar color for Revenue Growth
  const lineColor1 = '#2A5354'; // Line color for Revenue per Store
  const lineColor2 = '#54A7A8'; // Line color for Revenue Growth %
  const textColor = '#2A5354';  // Consistent text color

  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart
        data={data}
        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="Year" />
        <YAxis yAxisId="left" orientation="left" stroke={barColor1} />
        <YAxis yAxisId="right" orientation="right" stroke={lineColor1} />
        <Tooltip
          contentStyle={{
            backgroundColor: "rgba(255,255,255,0.8)",
            borderColor: "#ccc",
          }}
          formatter={(value: string, name: string) => {
            if (name === "Total Revenue") return formatRevenue(value);
            if (name === "Revenue per Store") return formatRevenuePerStore(value);
            if (name === "Revenue Growth") return formatRevenue(value);
            if (name === "Revenue Growth %") return formatPercentage(value);
            return value;
          }}
        />
        <Legend />
        <Bar yAxisId="left" dataKey="total_revenue" fill={barColor1} name="Total Revenue">
          {data.map((entry, index) => (
            <text
              key={`total-revenue-${index}`}
              x={index * 300 + 150}
              y={180}
              fill={textColor}
              textAnchor="middle"
              dy={-10}
            >
              {formatRevenue(entry.total_revenue)}
            </text>
          ))}
        </Bar>
        <Bar yAxisId="left" dataKey="revenue_growth" fill={barColor2} name="Revenue Growth">
          {data.map((entry, index) => (
            <text
              key={`revenue-growth-${index}`}
              x={index * 300 + 150}
              y={entry.revenue_growth ? 300 : 350}
              fill={textColor}
              textAnchor="middle"
              dy={-10}
            >
              {formatRevenue(entry.revenue_growth)}
            </text>
          ))}
        </Bar>
        <Line
          yAxisId="right"
          type="monotone"
          dataKey="revenue_per_store"
          stroke={lineColor1}
          name="Revenue per Store"
        >
          {data.map((entry, index) => (
            <text
              key={`revenue-per-store-${index}`}
              x={index * 300 + 150}
              y={100}
              fill={textColor}
              textAnchor="middle"
              dy={-10}
            >
              {formatRevenuePerStore(entry.revenue_per_store)}
            </text>
          ))}
        </Line>
        <Line
          yAxisId="right"
          type="monotone"
          dataKey="revenue_growth_percentage"
          stroke={lineColor2}
          name="Revenue Growth %"
        >
          {data.map((entry, index) => (
            <text
              key={`revenue-growth-percentage-${index}`}
              x={index * 300 + 150}
              y={50}
              fill={textColor}
              textAnchor="middle"
              dy={-10}
            >
              {formatPercentage(entry.revenue_growth_percentage)}
            </text>
          ))}
        </Line>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default RevenueGrowthChart;
